import axios from 'axios'
import { accountStates, accountTypes } from '@/settings'
import { auth0 } from '../../auth/auth0'

// helpers

// function to check if the proficed image data object still has a valid link
function isValidLink (imageData) {
  let expirationDate = new Date(imageData.expiresOnUtc)
  let buffer = 1000 * 120 // two minutes in millis
  let nowWithBuffer = Date.now() + buffer
  let expirationDateInMillis = expirationDate.valueOf()
  return nowWithBuffer < expirationDateInMillis
}

function isActiveClaim (claim) {
  return claim?.value?.toLowerCase() === 'active'
}

// initial state
const state = {
  id: auth0?.user?.sub,
  hasError: false,
  user: {
    firstName: null,
    lastName: null,
    title: null,
    gender: null,
    email: null,
    dateOfBirth: null,
    phone: null,
    address: {
      street1: null,
      street2: null,
      zipCode: null,
      city: null,
      stateOrProvince: null,
      country: null
    }
  },
  profileImageData: {
    uri: null,
    expiresOnUtc: null
  },
  institutionImageData: {
    uri: null,
    expiresOnUtc: null
  },
  institution: null,
  userLoaded: false,
  inactiveServices: [],
  lastLoadedServiceUrl: null,
  lastLoadedInactiveServiceUrl: null,
  termsOfUseUserStatus: null,
  serviceSettings: [],
  serviceClaims: [],
  potentialServiceClaims: [],
  isMedelEmployee: null
}

// getters
const getters = {
  id: state => state.id,
  accountType: (state, getters) => {
    if (getters.isRejected || getters.isBasic) {
      return accountTypes.BASIC
    }
    return accountTypes[getters.userType.toUpperCase()]
  },
  accountStatus: (state, getters) => {
    if (getters.isRejected || getters.isBasic) {
      return accountStates.NONE
    }
    if (getters.isVerified) {
      return accountStates.VERIFIED
    }
    return accountStates.PENDING
  },
  user: state => state.user,
  authUser: () => auth0.user,
  hasError: state => state.hasError,
  userLoaded: state => !!state.user.id,
  isVerified: state => state.user.registrationStatus === 'verified',
  isRejected: state => state.user.registrationStatus === 'rejected',
  isRecipient: state => state.user.userType === 'Recipient',
  isCareGiver: state => state.user.userType === 'Caregiver',
  isProfessional: state => state.user.userType === 'Professional',
  isOnline: state => state.user.userType === undefined,
  userType: state => state.user.userType,
  isBasic: state => state.user.registrationType === 'basic',
  recipientFullName: state => (!!state.user.recipientFirstName && !!state.user.recipientLastName) ? `${state.user.recipientFirstName} ${state.user.recipientLastName}` : '',
  fullName: state => `${state.user.firstName} ${state.user.lastName}`,
  title: state => state.user.title,
  countryString: state => `dropdowns.countries.${state.user.country}`,
  language: state => state.user.language ? state.user.language.toLowerCase() : null,
  userServices: state => {
    const services = state.serviceClaims.map(x => {
      const serviceDefinition = state.serviceSettings.find(y => y.id === x.type) || {}
      serviceDefinition.status = 'active'
      return serviceDefinition
    }).filter(x => !!x.id) // if the id is not in the serviceSettings, still display all other services

    return services
  },
  inactiveServices: state => {
    var services = state.potentialServiceClaims.map(x => {
      var serviceDefinition = state.serviceSettings.find(y => y.id === x.type) || {}
      serviceDefinition.status = 'inactive'
      return serviceDefinition
    }).filter(x => !!x.id) // if the id is not in the serviceSettings, still display all other services

    return services
  },
  termsOfUseUserStatus: state => state.termsOfUseUserStatus,
  isTermsOfUseConsentRequired: state => state.termsOfUseUserStatus?.isConsentRequired,
  userCountry: state => state.user?.country ? state.user.country.toLowerCase() : undefined,
  isEmailVerified: () => auth0?.user?.value?.email_verified,
  profileImageUrl: state => state.profileImageData.uri,
  hasValidProfilePicture: state => isValidLink(state.profileImageData),
  institutionImageUrl: state => state.institutionImageData.uri,
  hasValidInstitutionPicture: state => isValidLink(state.institutionImageData),
  canChangeUserType: (state, getters) => getters.isRejected || getters.isBasic,
  institution: (state) => state.institution,
  isMedelEmployee: (state) => state.user?.email?.includes('@medel.') && !state.user?.email?.includes('@medel.fake')
}

// actions, async changes (api calls, ...)
const actions = {
  async getInstitutionData ({ commit, getters }) {
    if (getters.institution) {
      return
    }
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    let config = {
      method: 'GET',
      url: `${process.env.VUE_APP_API_BASE}/users/${userId}/institution`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    try {
      var response = await axios(config)
      commit('setInstitution', response.data)
    } catch (error) {
      throw error
    }
  },

  async initiateEmailChange ({ getters }, newEmailAddress) {
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    var config = {
      method: 'POST',
      url: `${process.env.VUE_APP_API_BASE}/users/${userId}/email`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: {
        newEmail: newEmailAddress
      }
    }

    var response = await axios(config)
    return response
  },

  // After a user has requested to change the email address, he can confirm the new
  // email address with this API request. Code is the guid that identifies the email change request.
  async confirmEmailChange ({ getters }, data) {
    var config = {
      method: 'POST',
      url: `${process.env.VUE_APP_API_BASE}/users/emailchangeconfirmation`,
      data: {
        emailChangeToken: data.code,
        id: data.id
      }
    }
    var response = await axios(config)
    return response
  },

  async getUserFromDB ({ commit, getters }) {
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE}/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      commit('setUser', response.data)
      commit('setUserLoaded', true)
    } catch (err) {
      commit('setUserLoaded', false)
      throw err
    }

      // get upgrade data
    const upgradeResponse = await axios.get(`${process.env.VUE_APP_API_BASE}/users/${userId}/upgrades/status`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    commit('setUpgradeData', upgradeResponse.data)
  },

  async deleteProfile ({ getters }) {
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    await axios.delete(`${process.env.VUE_APP_API_BASE}/users/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  },

  async getProfileImageData ({ commit, getters }) {
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    if (getters.hasValidProfilePicture) {
      return
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_API_BASE}/users/${userId}/picture_access/medium`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
      commit('setProfileImage', response.data)
    } catch (err) {
      throw err
    }
  },

  async loadInstitutionImageData ({ commit, getters }) {
    if (getters.hasValidInstitutionPicture) {
      return
    }
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    try {
      let response = await axios.post(`${process.env.VUE_APP_API_BASE}/users/${userId}/institution/picture_access`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
      commit('setInstitutionImage', response.data)
    } catch (err) {
      throw err
    }
  },

  async getServiceClaims ({ commit, getters }) {
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    const url = `${process.env.VUE_APP_SAM_API_BASE}/user/${userId}/service_claims`
    try {
      var response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
      if (response.data && response.data.claims) {
        commit('setServiceClaims', response.data.claims)
      }
    } catch (err) {
      throw err
    }
  },

  async getServiceSettings ({ commit, getters }, language) {
    if (!language) {
      language = state.user.language ? state.user.language : 'en'
    }
    let token = await auth0.getAccessTokenSilently()

    const url = `${process.env.VUE_APP_API_BASE}/services?lang=${language}`
    if (url === state.lastLoadedServiceUrl) {
      return
    }
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    commit('setServiceDefinitions', response.data)
    commit('setLastLoadedServiceUrl', url)
  },

  async getInactiveServicesFromDB ({ commit, getters }) {
    if (getters.isOnline) {
      return
    }
    if (getters.isVerified) {
      commit('setPotentialServiceClaims', [])
    }
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    const url = `${process.env.VUE_APP_SAM_API_BASE}/user/${userId}/potential_service_claims`
    try {
      var response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
      if (response.data && response.data.claims) {
        commit('setPotentialServiceClaims', response.data.claims)
      }
    } catch (err) {
      throw err
    }
  },

  async getTermsOfUseUserStatus ({ commit, getters }) {
    // we already loaded the data once and no new consent is requred.
    if (state.termsOfUseUserStatus && state.termsOfUseUserStatus.IsConsentRequired === false) {
      return
    }

    // TODO cache
    const userCountry = this.getters['user/userCountry']
    if (!userCountry) {
      return
    }
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    const serviceId = 'mymedel'
    let response = await axios.get(`${process.env.VUE_APP_API_TERMSOFUSE_BASE}/terms-of-use/${serviceId}/users/${userId}/?country=${userCountry}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    commit('setTermsOfUseUserStatus', response.data)
  },

  async updateUserProfile ({ commit, getters }, data) {
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    try {
      var response = await axios.patch(`${process.env.VUE_APP_API_BASE}/users/${userId}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      // update the data in the app/local store
      commit('updateUser', response.data)
    } catch (error) {
      throw error
    }
  },

  async updateUserProfileWithFormData ({ commit, getters }, data) {
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    let config = {
      method: 'patch',
      url: `${process.env.VUE_APP_API_BASE}/users/${userId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
      data
    }
    let response = await axios(config)
    // update the data in the app/local store
    commit('updateUser', response.data)
  },

  // TODO: Await where we are calling this
  async acceptTermsOfUse ({ commit, getters }) {
    const userCountry = this.getters['user/userCountry']
    if (!userCountry) {
      throw new Error('cannot accept terms of use, because the user does not have a valid country.')
    }
    const serviceId = 'mymedel'

    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    let url = `${process.env.VUE_APP_API_TERMSOFUSE_BASE}/terms-of-use/${serviceId}/users/${userId}/?country=${userCountry}`
    let response = await axios.post(url, {}, config)
    commit('setTermsOfUseUserStatus', response.data)
  },

  // TODO: update callers
  async sendVerificationEmail ({ getters }) {
    let language = getters.language || 'en'
    const postData = {
      language: language
    }
    let token = await auth0.getAccessTokenSilently()
    let userId = getters.authUser?.value?.sub

    let url = `${process.env.VUE_APP_API_BASE}/users/${userId}/verification-email`
    let config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    await axios.post(url, postData, config)
  }
}

// mutations, sync changes
const mutations = {
  setUserLoaded (state, value) {
    state.userLoaded = value
  },

  setServiceDefinitions (state, data) {
    state.serviceSettings = data
    localStorage.setItem('serviceSettings', JSON.stringify(state.serviceSettings))
  },

  setServiceClaims (state, data) {
    state.serviceClaims = data.filter(x => isActiveClaim(x))
    localStorage.setItem('serviceClaims', JSON.stringify(state.serviceClaims))
  },

  setPotentialServiceClaims (state, data) {
    state.potentialServiceClaims = data.filter(x => isActiveClaim(x))
    localStorage.setItem('potentialServiceClaims', JSON.stringify(state.potentialServiceClaims))
  },
  setInstitution (state, data) {
    state.institution = data
    localStorage.setItem('institution', JSON.stringify(state.institution))
  },

  setProfileImage (state, data) {
    state.profileImageData.uri = data.uri
    state.profileImageData.expiresOnUtc = data.expiresOnUtc
    localStorage.setItem('profileImageData', JSON.stringify(state.profileImageData))
  },
  setInstitutionImage (state, data) {
    state.institutionImageData.uri = data.uri
    state.institutionImageData.expiresOnUtc = data.expiresOnUtc
    localStorage.setItem('institutionImageData', JSON.stringify(state.institutionImageData))
  },

  resetProfileImage (state, data) {
    state.profileImageData.uri = null
    state.profileImageData.expiresOnUtc = null
  },

  updateUser (state, data) {
    state.user = { ...state.user, ...data }
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  setUser (state, userData) {
    state.user = userData
    localStorage.setItem('user', JSON.stringify(userData))
  },

  setUpgradeData (state, userData) {
    state.user = { ...state.user, registrationType: userData.type, registrationStatus: userData.status }
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  setUserType (state, userType) {
    state.user = { ...state.user, userType }
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  setUserServices (state, services) {
    // in vue 2.x  there is a reactivity caveat with arrays and objects,
    // that's why we have to use this complicated syntax to populate the array
    // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
    while (state.userServices.length > 0) {
      state.userServices.pop()
    }
    for (let i = 0; i < services.length; i++) {
      state.userServices.push(services[i])
    }
  },

  setInactiveServices (state, services) {
    while (state.inactiveServices.length > 0) {
      state.inactiveServices.pop()
    }
    for (let i = 0; i < services.length; i++) {
      state.inactiveServices.push(services[i])
    }
  },

  setError (state, val) {
    state.hasError = val
  },

  setToken (state, val) {
    state.token = val
  },

  setLastLoadedServiceUrl (state, val) {
    state.lastLoadedServiceUrl = val
  },

  setLastLoadedInactiveServiceUrl (state, val) {
    state.lastLoadedInactiveServiceUrl = val
  },

  setTermsOfUseUserStatus (state, val) {
    state.termsOfUseUserStatus = val
  },

  initialiseStore (state) {
    if (localStorage.getItem('user')) {
      state.user = JSON.parse(localStorage.getItem('user'))
    }

    if (localStorage.getItem('serviceSettings')) {
      state.serviceSettings = JSON.parse(localStorage.getItem('serviceSettings'))
    }

    if (localStorage.getItem('serviceClaims')) {
      state.serviceClaims = JSON.parse(localStorage.getItem('serviceClaims'))
    }

    if (localStorage.getItem('potentialServiceClaims')) {
      state.potentialServiceClaims = JSON.parse(localStorage.getItem('potentialServiceClaims'))
    }

    var institutionImageDataRaw = localStorage.getItem('institutionImageData')
    if (institutionImageDataRaw) {
       var institutionImageData = JSON.parse(institutionImageDataRaw)
       state.institutionImageData.uri = institutionImageData.uri
       state.institutionImageData.expiresOnUtc = institutionImageData.expiresOnUtc
    }

    var profileImageDataRaw = localStorage.getItem('profileImageData')
    if (profileImageDataRaw) {
       var profileImageData = JSON.parse(profileImageDataRaw)
       state.profileImageData.uri = profileImageData.uri
       state.profileImageData.expiresOnUtc = profileImageData.expiresOnUtc
    }

    var institutionRaw = localStorage.getItem('institution')
    if (institutionRaw) {
      var institution = JSON.parse(institutionRaw)
      state.institution = institution
   }
  },

  resetStore (state) {
    localStorage.removeItem('institution')
    localStorage.removeItem('institutionImageData')
    localStorage.removeItem('profileImageData')
    localStorage.removeItem('user')
    localStorage.removeItem('serviceClaims')
    localStorage.removeItem('potentialServiceClaims')
    localStorage.removeItem('serviceSettings')
    state.user = {}
    state.institution = {}
    state.profileImageData = {}
    state.institutionImageData = {}
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
