<template>
  <footer class="Footer" id="footer">
    <div class="container">
      <div class="left">
        &copy; {{ currentYear }} MED-EL Medical Electronics. All rights reserved
      </div>

      <div class="right">
        <ul>
          <li>
            <a
              :href="$t('links.contact.link')"
              target="_blank"
              v-t="'links.contact.label'"
            />
          </li>

          <li>
            <a :href="dataPrivacyUrl" target="_blank">{{ $t('links.privacyPolicy.label') }}</a>
          </li>

          <li>
            <a
              :href="$t('links.legalNotice.link')"
              target="_blank"
              v-t="'links.legalNotice.label'"
            />
          </li>

          <li>
            <a :href="termsOfUseUrl"  target="_blank">{{ $t('links.termsOfUse.label') }}</a>
          </li>

          <!-- CookiePro Cookies List start
          <div id="ot-sdk-cookie-policy"></div> -->
          <li><a id="ot-sdk-btn" href="javascript:OneTrust.ToggleInfoDisplay()" class="optanon-toggle-display" style="cursor: pointer;">{{ $t('links.cookieSettings.label') }}</a></li>
          <!-- CookiePro Cookies List end -->

        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { appSettings } from '@/settings'

export default {
  name: 'ThePortalFooter',

  computed: {
    currentYear () {
      return new Date().getFullYear()
    },

    dataPrivacyUrl () {
      return appSettings.links?.dataPrivacy
    },

    termsOfUseUrl () {
      return appSettings.links?.termsOfUse
    }
  }
}
</script>

<style lang="scss">
@import '../styles/_component-imports';

.Footer {
  position: relative;
  color: $color-grey-100;
  background-color: $color-grey-700;
  font-size: 12px;
  padding: 22px 0;
  z-index: 1;

  @include media('>=sm') {
    padding: 22px 60px;
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    @include media('>=sm') {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }
  }

  .left {
    display: flex;
    align-items: center;
    color: $color-grey-400;
  }

  ul {
    list-style: none;
    display: flex;
    margin: 1em 0 0;
    padding: 0;
    line-height: 1;
    flex-direction: column;

    @include media('>=xs') {
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      flex-wrap: wrap;
    }

    @include media('>=sm') {
      margin-top: 2px;
    }
  }

  li {
    position: relative;
    display: block;

    @include media('>=xs') {
      padding: 0 14px;

      &:first-of-type {
        padding-left: 0;

        &::before {
          display: none;
        }
      }

      &:last-of-type {
        padding-right: 0;
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: $color-grey-100;
      }
    }
  }

  a {
    position: relative;
    display: inline-block;
    color: $color-grey-100;
    text-decoration: none;
    margin: 4px 0;
    white-space: nowrap;

    @include media('>=xs') {
      margin: 0;
    }

    &:hover {
      color: $white;

      &::after {
        transform: scale(1, 1);
        opacity: 1;
      }
    }

    &::after {
      @include animation-standard;

      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 1px;
      background-color: $white;
      transform: scale(0, 1);
      transform-origin: left center;
      opacity: 0;
    }
  }
}
</style>
